@keyframes gradient-rotation {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes slideRight {
    from {
        transform: translatex(-10%);
        opacity: 0;
    }
    to {
        transform: translatex(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translatey(40%);
        opacity: 0;
    }
    100% {
        transform: translatey(0);
        opacity: 1;
    }
}

@keyframes bounce-animation {
    0% {
        transform: translatey(-30%);
        opacity: 0;
    }
    50% {
        transform: translatey(10%);
        opacity: 0.7;
    }
    100% {
        transform: translatey(0);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}

@keyframes rotate-around {
    0% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(calc(100% - 30px), 0);
    }
    50% {
        transform: translate(calc(100% - 30px), calc(100% - 30px));
    }
    75% {
        transform: translate(0, calc(100% - 30px));
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
