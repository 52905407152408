//file drag drop
.ngx-file-drop__drop-zone {
    height: 100% !important;
    border-radius: 12px !important;
    border: 2px dashed var(--color-primary) !important;
    transition: border 0.5s ease-in-out, background 0.5s ease-in-out;
}

.ngx-file-drop__drop-zone:hover {
    border: 2px solid var(--color-primary) !important;
    background: rgba(231, 236, 252, 1) !important;
}

.ngx-file-drop__drop-zone:hover {
    border: 2px solid var(--color-primary) !important;
    background: rgba(231, 236, 252, 1) !important;
}

.ngx-file-drop__content {
    height: 100% !important;
    color: var(--color-tertiary) !important;
}

.ngx-file-drop__content button {
    font-size: 12px !important;
    padding: 4px 14px;
}

//mat toggle
.mdc-switch:enabled .mdc-switch__track::after {
    background: var(--color-primary) !important;
}

.mdc-switch__icon {
    background: var(--color-white) !important;
    outline: var(--color-primary) !important;
    border-radius: 50%;
}

//button
.mat-mdc-button {
    border: 1px solid var(--color-stroke) !important;
    color: var(--color-primary) !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: var(--color-white) !important;
    color: var(--color-tertiary) !important;
}

.mdc-snackbar__label {
    padding: 0 !important;
}

// mat dialog box
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    box-shadow: none !important;
}

//mat tab
.mdc-tab__text-label {
    color: var(--color-primary) !important;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: normal !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--color-primary) !important;
}
