@use "sass:meta";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@include meta.load-css("app/styles/override");
@include meta.load-css("app/styles/animation");

@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

button {
    font-family: 'Poppins', sans-serif !important;
}

ul {
    list-style-type: disc;
    list-style-position: inside;
}

ol {
    list-style-type: decimal;
    list-style-position: inside;
}

ul li {
    text-indent: -20px;
    padding-left: 20px;
}

ol li {
    text-indent: -17px;
    padding-left: 15px;
}

.font-poppins {
    font-family: 'Poppins', sans-serif;
}

.text-xxs {
    font-size: 10px;
}

.career-site-font-family {
    font-family: Poppins, sans-serif;
}

:root {
    --color-primary: #1E7BFA;
    --color-light-primary: #F3F7FB;
    --color-primary-contrast: #fff;
    --color-extra-light-primary: #368EFC;
    --color-black: #242424;
    --color-black-light-dark: #212121;
    --color-light-gray: #858585;
    --color-primary-dark: #0058D1;
    --color-primary-light: #EEF5FF;
    --color-white: #FFFFFF;
    --color-warning-light: #FFE6BB;
    --color-white-extra-dark-light: #E5E8E2;
    --color-white-light: #F0F0F0;
    --color-white-extra-light: #F0F2F4;
    --color-white-extra-dark: #F9FBFF;
    --color-extra-white-dark: #F0F0F0;
    --color-extra-white-dark-light: #FFFCFC;
    --color-light-white-gray: #E5E9EF;
    --color-white-dark: #F0F4F0;
    --color-gray-light: #9CAFC6;
    --color-gray: #99A2AF;
    --color-yellow: #E9A351;
    --color-purple: #4C3C6A;
    --color-purple-dark: #705481;
    --color-purple-light: #954AB9;
    --color-purple-extra-light: #7A6686;
    --color-purple-white-dark: #F2F0F4;
    --color-light-green: #95B464;
    --color-green-extra-dark: #6B8454;
    --color-extra-light-green: #FEFFFA;
    --color-gray-dark: #485A73;
    --color-black-light: #3D5371;
    --color-green-light-dark: #2B423A;
    --color-green-extra-white: #F0F4F0;
    --color-green-light: #55645F;
    --color-green-extra-light: #95A09C;
    --color-green-light-extra: #F9FAF1;
    --color-extra-light: #8E6F6B;
    --color-extra-brown-light: #B1A3A6;
    --color-extra-dark-brown: #63484E;
    --color-brown: #CD6767;
    --color-brown-extra-light: #F2F4F0;
    --color-brown-extra-dark: #63484E;
    --color-brown-light: #A59DB4;
    --color-white-slight-blue: #FCFDFF;
    --color-white-dark-light: #F6F6F6;
    --color-tertiary: #33455F;
    --color-box-shadow: #110c2e26;
    --color-extra-light-white: #F7FAFE;
    --color-light-chip-gray: #E7ECF8;
    --divider-color: #DEEDFF;
    --color-stroke: #DFEDFF;
    --color-light-extra-white: #EAEEFF;
    --color-tertiary-dark: #1A2C45;
    --color-primary-extra-light: #54A0FF;
    --color-primary-very-light-primary: #2d6dfe;
    --color-primary-extra-light-primary: #2c6bfc;
    --color-extra-primary-light: #F4F3F0;
    --border-color-light-dard: #d1d5db4d;
    --website-background: #F1F6FE;
    --smooth-white: #E4F1FF;
    --dark-extra-light-black: #686781;
    --extra-dark-blue: #1B2F64;
    --light-rgba: #080f3408;
    --color-tertiary-medium: #5A6F87;
    --color-blue-white-light: #EDF2F8;
    --color-blue-white-light-dark: #F6FAFF;
    --color-white-slight-dark: #F0F2F4;
    --color-tertiary-light: #8D96A2;
    --color-strok-hard: #fbfdffcc;
    --website-section-background: #ffffff4d;
    --title-color: #1a3066;
    --border-color: #e5f0ff;
    --chip-background-color: #F2F2F2;
    --dark-tertiary: #1E2E44;
    --light-gray: #546681;
    --light-extra-gray: rgba(133, 133, 133, 1);
    --website-box-shadow: #b0d4ff42 0 48px 150px;
    --light-white: #EBF3FF;
    --dark-red: #FF0000;
    --transparent-background: rgba(255, 255, 255, 0.50);
    --light-box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
    --color-success: #22C55E;
    --color-success-dark-light: #36C97F;
    --color-success-light: #BBF7D0;
    --color-warning: #F59E0B;
    --color-danger-light: #FCA5A5;
    --color-danger: #EF4444;
    --color-danger-light-dark: #FF6565;
    --color-danger-dark: #DC2626;
    --color-grey-dark: #3D5371;
    --color-grey-light-dark: #9CAFC6;
    --color-pink-dark: rgba(238, 0, 255, 1);
    --color-pink-extra-light: #EAE4F5;
    --color-pink-extra-dark: #4B3C6A;
    --color-blue-light: rgba(0, 243, 251, 1);
    --color-blue-dark: #000B31;
    --color-blue-medium: #0051FF;
    --color-dark-pink: #0e1229;
    --color-blue-extra-dark: #33455F;
    --color-slight-dark-pink: #3a3286;
    --color-extra-dark-pink: #0a153345;

    --progress-bar-color: #9E53FE;
    --progress-bar-secondary-color: #e7e8ea;
    --progress-bar-stroke-color: #3101FE;

    --mdc-switch-track-height: 22px !important;
    --mdc-switch-track-shape: 20px;
    --mdc-switch-track-width: 46px;

    --blue-gradient-color: linear-gradient(#00bfff, #00bfff);
    --blue-blur-box-shadow: 0 0 15px rgba(0, 191, 255, 0.8);
    --pink-gradient-color: linear-gradient(#d500ff, #d500ff);
    --pink-blur-box-shadow: 0 0 15px rgba(213, 0, 255, 0.8);
    --dark-and-light-blue-gradient: linear-gradient(90deg, rgba(52, 63, 253, 1) 44%, rgba(84, 236, 255, 1) 100%);

    --talenlio-background-gradient-color: linear-gradient(90deg, rgba(52, 63, 253, 1) 44%,
        rgba(84, 236, 255, 1) 100%) no-repeat center;

    --color-brown-light-blur: rgba(90, 63, 69, 0.16);
    //box shadow
    --card-box-shadow: 0px 7px 14px 0px rgba(8, 15, 52, 0.03);

}

.color-primary {
    color: var(--color-primary) !important;
}

.bg-primary {
    background: var(--color-primary) !important;
}

.bg-tertiary {
    background: var(--color-tertiary) !important;
}

.border-stroke {
    border: 1px solid var(--color-stroke);
}

.text-green {
    color: var(--color-green-light-dark);
}

.border-white-light {
    border: 1px solid var(--color-white-extra-light);
}

.text-white {
    color: var(--color-white);
}

.bg-white {
    background: var(--color-white) !important;
}

.text-heading {
    color: var(--color-tertiary);
}

.text-danger {
    color: var(--color-danger-light-dark);
}

.text-heading-light {
    color: var(--color-black-extra-light);
}

.text-subheading {
    color: var(--color-grey-light-dark);
}

.text-heading-primary {
    color: var(--color-grey-dark);
}

.text-heading-secondary {
    color: var(--color-grey-light-dark);
}

.border-stroke {
    border: 1px solid var(--color-stroke);
}

.white-card-container {
    border-radius: 32px;
    border: 2px solid var(--color-white);
    background: var(--transparent-background);
    backdrop-filter: blur(15px);
}

.white-card-shadow-container {
    border-radius: 12px;
    border: 2px solid var(--color-white);
    background: var(--color-white);
    box-shadow: var(--light-box-shadow);
    backdrop-filter: blur(15px);
}

.white-square-card-container {
    border: 2px solid var(--color-white);
    background: var(--color-white);
    box-shadow: var(--light-box-shadow);
    backdrop-filter: blur(15px);
}

.box-shadow {
    box-shadow: var(--light-box-shadow);
}

.light-box-shadow {
    box-shadow: var(--card-box-shadow);
}

.section-card {
    border: 1px solid var(--color-white-dark);
    border-radius: 8px;
    padding: 10px;
    background: var(--transparent-background);;
}

.chip {
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 8px;
    width: fit-content;
}

.horizontal-separator {
    border: solid 1px;
}

.resume-section-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    font-family: Poppins, sans-serif;
}

.resume-section-container-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    font-family: Poppins, sans-serif;
}

.resume-section-container-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    font-family: Poppins, sans-serif;
}

.resume-section-container-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    font-family: Poppins, sans-serif;
}

.resume-section-title-bottom {
    margin-bottom: 8px;
}

.resume-section-content-between {
    margin-bottom: 8px;
}

.resume-section-content-between:nth-last-child(1) {
    margin-bottom: 12px;
}

.resume-horizontal-line-below-distance {
    margin-bottom: 8px;
}

@media (max-width: 650px) {
    .resume-section-title {
        font-size: 13px;
        line-height: 18px;
    }

    .resume-section-container-title {
        font-size: 12px;
        line-height: 16px;
    }

    .resume-section-container-text {
        font-size: 10px;
        line-height: 14px;
    }

    .resume-section-container-description {
        font-size: 10px;
        line-height: 16px;
    }
}

//custom-snack-bar
.snack-bar-container {
    border-radius: 14px !important;
    padding: 4px !important;
    background: var(--color-white);
    color: var(--color-black);
    box-shadow: none !important;
}

.welcome-snack-bar {
    border: 2px solid var(--color-primary);
}

.warning-snack-bar {
    border: 2px solid var(--color-warning);
}

.info-snack-bar {
    border: 2px solid var(--color-primary);
}

.success-snack-bar {
    border: 2px solid var(--color-success-dark-light);
}

.danger-snack-bar {
    border: 2px solid var(--color-danger);
}

//animation type
.animate-slide-right {
    animation: slideRight 0.3s ease-out forwards;
}

.animate-slide-up {
    animation: slideUp 0.7s ease-out forwards;
}

.animate-bounce {
    animation: bounce-animation 0.4s ease-out forwards;
}

.animation-fade {
    animation: fade 5s ease-in-out infinite;
}

.animation-fade-in {
    animation: fade-in 1s ease-in forwards;
}

.slide-in-left {
    animation: slideInLeft 0.5s forwards;
}

.slide-in-right {
    animation: slideInRight 0.5s forwards;
}

.zoom-in {
    animation: zoomIn 0.5s forwards;
}
